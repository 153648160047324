.dropdown-menu, .social-menu  {
  position: relative;
  display: inline-block;
  .dropdown-content {
    display: none;
    position: absolute;
    padding-top: 30px;
    background-clip: content-box;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(22, 22, 22, 0.8);
    min-width: 242px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 30;
    .icon-container {
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      width: 30px;
    }
    .link-header {
      color: $avo-grey-3;
      padding: 13px 34px;
      text-decoration: none;
      &:hover {
        background-color: $avo-grey-7;
      }
      .dropdown-icon {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }
  .pancake-swap-content {
    position: absolute;
    padding: 0 !important;
    background-clip: content-box;
    background-color: rgba(22, 22, 22, 0.8);
    width: 162px;
    top: 0;
    right: calc(-50% - 47px);
    z-index: 30;
    a {
      padding: 15px 0 15px 24px;
      &:hover {
        background-color: $avo-grey-7;
      }
    }
  }
  .show-dropdown {
    display: block;
  }
}
.social-menu {
  position: fixed;
  top: 32%;
  transform: translateY(-50%);
  right: 4px;
  border-radius: 26px;
  background-color: rgba(22, 22, 22, 0.8);
  padding: 28px 14px;
  z-index: 30;

  a {
    margin-bottom: 20px;
    &:last-child {
      margin: 0;
    }
  }
}