.connected-account-container {
  display: block;
  background: linear-gradient(90deg, #8f01ff 0%, #5001b6 88.33%);
  border-radius: 8px;
  padding: 12px;
  width: 205px;

  .account-number {
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
