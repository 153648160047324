.transmissions-section {
  margin-top: 60px;
  align-self: center;
  position: relative;
  z-index: 10;

  .astro-man {
    position: absolute;
    left: 45vw;
    z-index: 10;
  }

  .avo-planet {
    position: absolute;
    bottom: 37%;
    right: 0;
    z-index: 3;
  }

  .footer-background {
    bottom: 32%;
  }

  .transmissions-box {
    background: linear-gradient(
      268.76deg,
      rgba(98, 97, 49, 0.6) 23.94%,
      rgba(99, 139, 67, 0.6) 119.4%
    );
    backdrop-filter: blur(40px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 12px;
    padding: 50px;
    width: 73%;

    .receive-transmissions {
      width: 45%;
    }

    .enter-email {
      border-style: solid;
      border-width: 0;
      border-bottom-width: 1px;
      border-color: $avo-grey-1;
      width: 45%;

      .email-input {
        background-color: transparent;
        border-width: 0px;
        color: $avo-grey-1;
        height: 40px;
        width: 90%;
        font-size: 18px;
        line-height: 24px;
        font-weight: $poppins-regular;
        font-family: $font-family-base;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $avo-grey-1;
          opacity: 1; /* Firefox */
          line-height: 24px;
          font-weight: $poppins-regular;
          font-family: $font-family-base;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: $avo-grey-1;
          line-height: 24px;
          font-weight: $poppins-regular;
          font-family: $font-family-base;
        }
      }
      .submit-button {
        height: 40px;
        background-color: transparent;
      }
    }
  }

  .community-section {
    margin-top: 200px;
    margin-bottom: 290px;
    align-self: flex-start;
    max-width: 470px;
    margin-left: 12%;
    z-index: 15;

    .community-text {
      align-self: flex-start;

      .community-link {
        margin-top: 20px;

        &::after {
          margin-top: 20px;
          margin-left: 10px;
          content: url("../../images/icons/external-link.svg");
        }
      }

      .community-cta {
        font-size: 72px;
        line-height: 82px;
      }
    }
  }
}

@media only screen and (min-width: 2290px) {
  .transmissions-section {
    .astro-man {
      bottom: 50%;
    }

    .avo-planet {
      bottom: 43%;
    }

    .footer-background {
      bottom: 28%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .transmissions-section {
    margin-top: 60px;

    .astro-man {
      bottom: 52%;
      left: 20%;
    }

    .avo-planet {
      bottom: 51%;
      right: 25px;
    }

    .footer-background {
      bottom: 44%;
    }

    .transmissions-box {
      flex-direction: column;
      padding: 30px;

      .receive-transmissions {
        width: 100%;
      }

      .enter-email {
        margin-top: 20px;
        width: 100%;
      }
    }

    .community-section {
      margin-left: 11%;
      margin-top: 70px;

      .community-text {
        .community-cta {
          font-size: 32px;
          line-height: 55px;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .transmissions-section {
    margin-top: 40px;

    .footer-landscape-mobile {
      bottom: 45%;
    }

    .transmissions-box {
      padding: 20px;
      width: 80%;

      .receive-transmissions {
        .box-title {
          font-size: 22px;
        }
      }
    }

    .community-section {
      margin-left: 25px;
      margin-right: 25px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .transmissions-section {
    .footer-landscape-mobile {
      bottom: 43%;
    }

    .avo-planet {
      bottom: 45%;
      right: 5px;
    }
  }
}
