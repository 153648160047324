.header-main-section {
    z-index: 9;        
    position: absolute;
    justify-content: center;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 20px;        
//    width: 1439px;
    height: 120px;
    left: 0px;
    top: 110px;
    background: rgba(28, 12, 50, 0.25);
    border: solid;
    border-image: url('../../images/header-main-section-border.svg') 2/3px round;;
    mix-blend-mode: normal; 

    .container {
        height: 600px;
        display: grid;
        grid-gap: 3vw;
        justify-content: center;
//        gap: 3vw;
        width:100%;
    
        .header-box {
            position: static;
            width: 240px;
            height: 20px;
            left: 0px;
            top: 60px;
            align-items: center;
            text-align: center;
            justify-content: center;             
            h1{
                text-transform: uppercase;
                margin-top: 5px;
                margin-bottom: 10px;
                font-family: $font-family-gilroy;
                font-style: normal;
                font-weight: 800;
                font-size: 36px;
                line-height: 37px;
                display: inline-block;
                letter-spacing: 0.06em;
                // background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                // background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffad26), color-stop(50%,#fea026), color-stop(51%,#ff8b00), color-stop(100%,#ff7701)); /* Chrome,Safari4+ */
                // background: -webkit-linear-gradient(top,  #ffad26 0%,#fea026 50%,#ff8b00 51%,#ff7701 100%); /* Chrome10+,Safari5.1+ */
                background: linear-gradient(180deg,#fff,hsla(0,0%,100%,0));
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
            h4{
                font-size: 16px;
                line-height: 19px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.06em;
                justify-content: center;
                color: #FFFFFF;

            }
        }
      }

}
@media (min-width: 1024px) {
    .container { grid-template-columns: repeat(5, 1fr); }
}
@media only screen and (max-width: 1024px) {
    .header-main-section {
        top: 95px;
        height: 80px;
        padding: 10px;  
        .container {
            height: 400px;
            display: flex;
            justify-content: center;
            gap: 3vw;
            width:100%;
        
            .header-box {
                position: static;
                width: 240px;
                height: 20px;
                left: 0px;
                top: 40px;
                align-items: center;
                text-align: center;
                justify-content: center;             
                
                h1{
                    font-family: $font-family-gilroy;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 28px;
                    line-height: 28px;
                    display: inline-block;
                    letter-spacing: 0.06em;
                    // background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                    // background: -webkit-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); /* Chrome,Safari4+ */
                    // background: -webkit-linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10+,Safari5.1+ */
                    background: linear-gradient(180deg,#fff,hsla(0,0%,100%,0));
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
                h4{
                    font-size: 12px;
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.02em;
                    justify-content: center;
                    color: #FFFFFF;
    
                }
            }
          }
    }
}
@media only screen and (min-width: 1024px) {
    .header-main-section {
        top: 95px;
        height: 80px;
        padding: 10px;  
        .container {
            height: 400px;
            display: flex;
            justify-content: center;
            gap: 3vw;
            width:100%;
        
            .header-box {
                position: static;
                width: 240px;
                height: 20px;
                left: 0px;
                top: 40px;
                align-items: center;
                text-align: center;
                justify-content: center;             
            
                h1{
                    font-family: $font-family-gilroy;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 28px;
                    line-height: 28px;
                    display: inline-block;
                    letter-spacing: 0.06em;
                    text-transform: uppercase;
                    // background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                    // background: -webkit-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); /* Chrome,Safari4+ */
                    // background: -webkit-linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10+,Safari5.1+ */
                    background: linear-gradient(180deg,#fff,hsla(0,0%,100%,0));          
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
                h4{
                    font-size: 12px;
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.02em;
                    justify-content: center;
                    color: #FFFFFF;
    
                }
            }
          }
    }
}
@media only screen and (max-width: 768px) {
    .header-main-section {
        top: 85px;
        height: 150px;
        padding: 10px; 
        .container {
            grid-template-columns: repeat(3, 1fr);
            height: 600px;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1em !important;
            justify-content: center;
            gap: 20px;
            width:100%;

            // Just in case disable min height for mobile
            min-height: 95px;
            margin: 0;

            .header-box {
//                position: static;
                justify-content: center;                
                width: 140px;
                height: 20px;
                left: 10px;
                top: 43px;
                align-items: center;
                text-align: center;
            
                h1{
                    font-family: $font-family-gilroy;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 26px;
                    line-height: 28px;
                    display: inline-block;
                    letter-spacing: 0.06em;
                    // background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                    // background: -webkit-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); /* Chrome,Safari4+ */
                    // background: -webkit-linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10+,Safari5.1+ */
                    background: linear-gradient(180deg,#fff,hsla(0,0%,100%,0));
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
                h4{
                    font-size: 10px;
                    line-height: 12px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.02em;
                    justify-content: center;
                    color: #FFFFFF;
    
                }
            }
          }
    }
  }
@media only screen and (max-width: 480px) {
    .header-main-section {
        // top: 0px;
        top: -13px;
        height: 140px;
//        padding: 10px; 
        .container {
            grid-template-columns: repeat(3, 1fr);
            height: 600px;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 0em !important;
            justify-content: center;
            gap: 2vw;

            // Just in case disable min height for mobile
            min-height: 95px;
            margin: 0;
        
            .header-box {
                width: 100px;
                height: 20px;
                left: 0px;
                top: 43px;
                justify-content: center; 
                align-items: center;
                text-align: center;                
                h1{
                    font-family: $font-family-gilroy;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 16px;
                    display: inline-block;
                    letter-spacing: 0.06em;
                    text-transform: uppercase;
                    // background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                    // background: -webkit-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); /* Chrome,Safari4+ */
                    // background: -webkit-linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); /* Chrome10+,Safari5.1+ */
                    background: linear-gradient(180deg,#fff,hsla(0,0%,100%,0));
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
                h4{
                    font-size: 10px;
                    line-height: 10px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.02em;
                    justify-content: center;
                    color: #FFFFFF;
    
                }
            }
          }
    }
  }
  