.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  pointer-events: auto;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
}
