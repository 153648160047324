.game-section {
  padding-bottom: 700px;
  position: relative;
  margin-top: 100px;

  .game-objects {
    width: 100vw;

    .stars {
      z-index: -9;
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
    }

    .background-planets {
      z-index: -8;
      position: absolute;
      width: 876.64px;
      width: 60vw;
      height: auto;
      align-self: center;
      top: 2vh;
    }

    .secondary-planets {
      z-index: -7;
      position: absolute;
      width: 100%;
      height: auto;
      top: 20vh;
    }

    .revv-planet {
      position: absolute;
      width: 24%;
      aspect-ratio: 1;
      right: 10vw;
      top: 15vh;
    }

    .revv-touchable {
      width: 84%;
      aspect-ratio: 1;
      top: 8%;
      right: 8%;
    }

    .cyball-planet {
      position: absolute;
      width: 25%;
      height: auto;
      left: 20vw;
      top: 45vh;
    }

    .cyball-touchable {
      width: 95%;
      aspect-ratio: 1;
      top: 2.5%;
      right: 2.5%;
      cursor: pointer;
    }

    .axie-infinity-planet {
      position: absolute;
      width: 38%;
      height: auto;
      right: 15vw;
      top: 45vh;
    }

    .axie-infinity-touchable {
      width: 78%;
      aspect-ratio: 1;
      top: 11%;
      right: 11%;
      cursor: pointer;
    }

    .elpis-planet {
      position: absolute;
      width: 20%;
      height: auto;
      left: 15vw;
      top: 10vh;
    }

    .elpis-touchable {
      width: 92%;
      aspect-ratio: 1;
      top: 4%;
      right: 4%;
      cursor: pointer;
    }

    .thethan-planet {
      position: absolute;
      width: 24%;
      height: auto;
      left: 26vw;
      top: 90vh;
    }

    .thethan-touchable {
      width: 88%;
      aspect-ratio: 1;
      top: 6%;
      left: 6%;
      cursor: pointer;
    }

    .sipher-planet {
      position: absolute;
      width: 28%;
      height: auto;
      left: 0vw;
      top: 80vh;
    }

    .sipher-touchable {
      width: 70%;
      aspect-ratio: 1;
      top: 15%;
      left: 13%;
      cursor: pointer;
    }

    .big-time-planet {
      position: absolute;
      width: 26%;
      height: auto;
      right: 2vw;
      top: 87vh;
    }

    .big-time-touchable {
      width: 86%;
      aspect-ratio: 1;
      top: 7%;
      left: 7%;
      cursor: pointer;
    }

    .planet {
      width: 100%;
      aspect-ratio: 1;
    }

    .touchable-space {
      z-index: 20;
      position: absolute;
      cursor: pointer;
      border-radius: 1000px;
      // transform: rotate(-77.56deg);
      // opacity: 0.3;
    }
  }

  .popup-hidden {
    display: none;
  }

  .game-popup {
    z-index: 100;
    position: absolute;
    background: rgba(22, 22, 22, 0.6);
    backdrop-filter: blur(40px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 8px;
    padding: 24px 0 24px 39px;

    &.spacing {
      margin-top: 24px;
    }

    .game-popup-content {
      .game-popup-header {
        justify-content: space-between;
        padding-right: 100px;

        .title {
          margin-top: 7px;
        }

        .cross {
          position: absolute;
          content: url(./../../images/icons/cross.svg);
          width: 28px;
          height: 28px;
          right: 24px;

          cursor: pointer;
        }
      }
      .game-popup-body {
        line-height: 150%;
        width: 90%;
      }
    }

    .view-more-link {
      text-decoration: none;
    }

    .view-more-button {
      padding-left: 0;
      text-transform: none;

      &.spacing {
        margin-top: 21px;
      }
    }
  }

  .revv-popup {
    opacity: 1;
    position: absolute;
    width: 25%;
    height: auto;
    right: 7vw;
    top: 15vh;
  }

  .cyball-popup {
    position: absolute;
    width: 30%;
    height: auto;
    left: 15vw;
    top: 50vh;
  }

  .axie-infinity-popup {
    position: absolute;
    width: 30%;
    height: auto;
    right: 18vw;
    top: 60vh;
  }

  .elpis-popup {
    position: absolute;
    width: 24%;
    height: auto;
    left: 10vw;
    top: 14vh;
  }

  .thethan-popup {
    position: absolute;
    width: 30%;
    height: auto;
    left: 30vw;
    top: 100vh;
  }

  .sipher-popup {
    position: absolute;
    width: 30%;
    height: auto;
    left: 2vw;
    top: 93vh;
  }

  .big-time-popup {
    position: absolute;
    width: 30%;
    height: auto;
    right: 2vw;
    top: 98vh;
  }

  .game-heading {
    margin-top: 110px;
    z-index: 10;
    width: 55%;
    align-self: center;

    .regular {
      font-size: 20px;
      line-height: 30px;
      font-weight: $poppins-regular;
      color: $avo-grey-2;
    }

    .ag-planet {
      margin-top: -50px;
      width: 25vw;
      height: auto;
    }

    &.spacing {
      margin-bottom: 24.17px;
    }
  }

  .headline {
    &.spacing {
      margin-bottom: 20px;
    }
  }

  .game-header-title {
    font-size: 50px;
    text-align: center;
    letter-spacing: -0.02em;

    &.spacing {
      margin-bottom: 18.4px;
    }
  }
}

@media only screen and (min-width: 2000px) {
  .game-section {
    margin-top: 0px;
    .game-objects {
      .revv-planet {
        max-width: 450px;
        right: 16vw;
        top: 9vh;
      }

      .cyball-planet {
        position: absolute;
        max-width: 480px;
        left: 25vw;
        top: 30vh;
      }

      .axie-infinity-planet {
        position: absolute;
        max-width: 650px;
        right: 20vw;
        top: 35vh;
      }

      .elpis-planet {
        position: absolute;
        max-width: 350px;
        left: 20vw;
        top: 8vh;
      }

      .thethan-planet {
        position: absolute;
        max-width: 450px;
        left: 35vw;
        top: 65vh;
      }

      .sipher-planet {
        position: absolute;
        max-width: 480px;
        left: 10vw;
        top: 60vh;
      }

      .big-time-planet {
        position: absolute;
        max-width: 400px;
        height: auto;
        right: 10vw;
        top: 60vh;
      }
    }

    .revv-popup {
      opacity: 1;
      position: absolute;
      width: 20%;
      height: auto;
      right: 7vw;
      top: 15vh;
    }

    .cyball-popup {
      width: 25%;
      left: 20vw;
      top: 35vh;
    }

    .axie-infinity-popup {
      width: 25%;
      right: 18vw;
      top: 43vh;
    }

    .elpis-popup {
      width: 20%;
      left: 10vw;
      top: 14vh;
    }

    .thethan-popup {
      position: absolute;
      width: 25%;
      height: auto;
      left: 30vw;
      top: 70vh;
    }

    .sipher-popup {
      position: absolute;
      width: 25%;
      height: auto;
      left: 2vw;
      top: 70vh;
    }

    .big-time-popup {
      position: absolute;
      width: 25%;
      height: auto;
      right: 2vw;
      top: 68vh;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .game-section {
    .game-objects {
      .cyball-planet {
        top: 35vh;
      }
      .axie-infinity-planet {
        top: 40vh;
      }

      .sipher-planet {
        top: 60vh;
      }

      .thethan-planet {
        top: 75vh;
      }

      .big-time-planet {
        top: 75vh;
      }
    }

    .cyball-popup {
      top: 38vh;
    }
    .axie-infinity-popup {
      top: 48vh;
    }
    .sipher-popup {
      top: 68vh;
    }
    .thethan-popup {
      top: 80vh;
    }
    .big-time-popup {
      top: 82vh;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .game-section {
    margin-top: 0px;
    padding-bottom: 500px;

    .game-objects {
      .revv-planet {
        right: 10vw;
        top: 5vh;
      }

      .cyball-planet {
        position: absolute;
        width: 25%;
        height: auto;
        left: 20vw;
        top: 13vh;
      }

      .axie-infinity-planet {
        position: absolute;
        width: 38%;
        height: auto;
        right: 15vw;
        top: 20vh;
      }

      .elpis-planet {
        position: absolute;
        width: 20%;
        height: auto;
        left: 15vw;
        top: 0vh;
      }

      .thethan-planet {
        position: absolute;
        width: 24%;
        height: auto;
        left: 26vw;
        top: 30vh;
      }

      .sipher-planet {
        position: absolute;
        width: 28%;
        height: auto;
        left: 0vw;
        top: 20vh;
      }

      .big-time-planet {
        position: absolute;
        width: 26%;
        height: auto;
        right: 2vw;
        top: 36vh;
      }
    }

    .popup-hidden {
      display: none;
    }

    .game-popup {
      z-index: 10;
      top: 0px;
      background: rgba(22, 22, 22, 0.8);
      // /* Note: backdrop-filter has minimal browser support */
      width: 80%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-between;

      &.spacing {
        margin-top: 24px;
      }

      .game-popup-header {
        min-width: 0;
        justify-content: space-between;
        margin-bottom: 20px;

        .title {
          margin-top: 0px;
          margin-bottom: 0px;
        }

        .cross {
          position: absolute;
          content: url(./../../images/icons/cross.svg);
          width: 28px;
          height: 28px;
          right: 24px;

          cursor: pointer;
        }
      }

      .game-popup-body {
        font-size: larger;
        line-height: 150%;
        min-width: 0;
      }
      .view-more-link {
        text-decoration: none;
      }

      .view-more-button {
        padding-left: 0;
        text-transform: none;

        &.spacing {
          margin-top: 21px;
        }
      }
    }

    .small-device {
      z-index: 100;
      min-width: 80%;
      height: 30%;
      top: 10vh;
    }

    .game-heading {
      margin-top: 0;
      margin-left: 0;
      width: 90%;

      .regular {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
      }

      .ag-planet {
        margin-top: 0px;
      }

      &.spacing {
        margin-bottom: 24.17px;
      }
    }

    .headline {
      &.spacing {
        margin-bottom: 20px;
      }
    }

    .game-header-title {
      font-size: 32px;
      letter-spacing: -0.02em;

      &.spacing {
        margin-bottom: 9.64px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .game-section {
    margin-bottom: 20px;
    margin-top: 0;
    padding-top: 0;
    z-index: 10;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    padding-bottom: 350px;

    .game-objects {
      width: 100vw;
      left: 0;

      .background-planets {
        width: 80vw;
        height: auto;
        top: 6vh;
      }

      .secondary-planets {
        top: 10vh;
      }

      .revv-planet {
        top: 8vh;
      }

      .cyball-planet {
        top: 15vh;
      }

      .axie-infinity-planet {
        right: 16vw;
        top: 16vh;
      }

      .elpis-planet {
        top: 8vh;
      }

      .thethan-planet {
        width: 30vw;
        top: 35vh;
      }

      .sipher-planet {
        width: 32vw;
        left: 10vw;
        top: 24vh;
      }

      .big-time-planet {
        width: 32vw;
        right: 10vw;
        top: 29vh;
      }
    }



    .game-popup {
      z-index: 10;
      top: 0px;
      background: rgba(22, 22, 22, 0.8);
      // /* Note: backdrop-filter has minimal browser support */
      width: 80%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-between;

      &.spacing {
        margin-top: 24px;
      }

      .game-popup-header {
        min-width: 0;
        justify-content: space-between;
        margin-bottom: 20px;

        .title {
          margin-top: 0px;
          margin-bottom: 0px;
        }

        .cross {
          position: absolute;
          content: url(./../../images/icons/cross.svg);
          width: 28px;
          height: 28px;
          right: 24px;

          cursor: pointer;
        }
      }

      .game-popup-body {
        line-height: 150%;
        min-width: 0;
      }
      .view-more-link {
        text-decoration: none;
      }

      .view-more-button {
        padding-left: 0;
        text-transform: none;

        &.spacing {
          margin-top: 21px;
        }
      }
    }

    .small-device {
      z-index: 100;
      min-width: 80%;
      height: 75%;
      overflow: scroll;
      top: -5vh;
    }

    .game-heading {
      margin-top: 0;
      margin-left: 0;
      width: 90%;

      .regular {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
      }

      .ag-planet {
        width: 30vw;
        margin-top: 0;
      }

      &.spacing {
        margin-bottom: 24.17px;
      }
    }

    .headline {
      &.spacing {
        margin-bottom: 20px;
      }
    }

    .game-header-title {
      font-size: 32px;
      letter-spacing: -0.02em;

      &.spacing {
        margin-bottom: 9.64px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .game-section {
    padding-bottom: 250px;
  }
}
