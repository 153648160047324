.audit-section {
    font-family: "Furore";
    font-size: 1.1vw;
    font-style: normal;
    font-weight: normal;
    color: white;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 60vw;
    z-index: 100;
    letter-spacing: 3px;
}

.audit-section a {
    text-decoration: none;
}

.audit-section a img {
    width: 12%; 
    margin-top: 1%;
}

@media only screen and (max-width:768px) {
    .audit-section {
        top: 450px;
        font-size: 3vw;
        letter-spacing: 2px;
    }

    .audit-section a img {
        width: 35%; 
        margin-top: 2%;
    }
}