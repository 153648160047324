.main-games:after {
  content: '';
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  right: 0;
  z-index: -10;
  background-color: #273136;
  background-image: linear-gradient(180deg,
      rgba(17, 23, 34, 0.9) 0,
      #0d101b 100%);
  background-image: url("../../../assets/images/games/cover.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: initial;
  height: 100%;
  transition: background 0.2s linear;
  background-size: cover;
}

.main-games:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -5;
  background: linear-gradient(180deg, rgba(17, 23, 34, 0.9) 0, #0d101b 100%);
}

.main-games {
  padding: 82px 0 20px;
  &--detail {
    padding-bottom: 0;
  }
}

.main-games .content {
  text-align: center;
  padding-top: 82px;
}

.fade {
  transition: opacity 0.15s linear;
  animation: fadeInAnimation ease 0.75s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

.game {
  &__container {
    padding: 20px 0;
    color: #ffffffbf;
  }
  &__main-container {
    padding: 20px 0 0 30px;
    color: #ffffffbf;
  }
  &__wrapper {
    position: relative;
    overflow: auto;
    padding-right: calc(1vw + 2px);
  }
  &__highlight {
    position: absolute;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -1;
    background-position: center;
  }

  &__title {
    margin-bottom: calc(1vw + 2px);
    color: #39ba96;
    line-height: 29px;
    font-size: 30px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: calc(1vw + 2px);
    margin-bottom: calc(1vw + 2px);
    z-index: 2;
  }

  &__description {
    margin-left: calc(1vw + 2px);
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__preview {
    z-index: 2;
    width: 25%;
    object-fit: cover;
  }

  &__navigation {
    margin-top: calc(1vw + 2px);
    display: flex;
    align-items: center;
  }

  &__button {
    margin-right: 2vw;
    color: #39ba96;
    text-decoration: none !important;
    display: flex;
    align-items: center;
  }

  &__btn-container {
    display: none;
  }

  &__button-icon {
    margin-left: 1vw;
    width: calc(1vw - 3px);
  }

  &__list-title {
    margin-left: calc(1vw + 2px);
  }

  &__list {
    margin-top: calc(1vw + 2px) !important;
    margin-right: calc(1vw + 2px) !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: calc(1vw + 3px);
    column-gap: calc(1vw + 3px);
    padding: 0;
  }

  &__separator {
    margin-bottom: 0;
    border-bottom: 1.5px solid #5a6672;
  }

  &__item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &--active {
      border: 3px solid #39ba96;
    }
    &__menu-container {
      position: relative;
    }
    &__button-text-menu {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &__menu-section {
      padding: 15px 25px;
      border-radius: 4px;
      border: 1px solid #39ba96;
      background-color: #1e2936;
      position: absolute;
      left: 0;
      top: 40px;
      z-index: 7;
    }
    &__menu-item {
      color: #39ba96;
      font-weight: 700;
      margin-top: 15px;
      width: 135px;
      justify-content: space-between;
      display: flex;
      padding: 0;
    }
    &__menu-item:first-child {
      margin-top: 0;
    }
    &__menu-item-img {
      height: 15px;
    }
  }
  &__menu-container {
    position: relative;
  }
  &__button-item {
    text-align: center;
    border: 1px solid #39ba96;
    color: #39ba96;
    font-weight: 700;
    padding: 7px;
    border-radius: 4px;
    margin-right: 6px;
    &--menu {
      width: 43px;
      height: 100%;
      font-size: 18px;
      margin-right: 0;
      position: relative;
    }
  }
  &__menu-section {
    padding: 15px 25px;
    border-radius: 4px;
    border: 1px solid #39ba96;
    background-color: #1e2936;
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 7;
  }
  &__menu-item {
    color: #39ba96;
    font-weight: 700;
    margin-top: 15px;
    width: 135px;
    justify-content: space-between;
    display: flex;
    padding: 0;
  }
  &__menu-item:first-child {
    margin-top: 0;
  }
  &__menu-item-img {
    height: 15px;
    margin-left: 7px;
  }
}

.game-detail {
  &__background {
    position: absolute;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -1;
  }
  &__container {
    z-index: 2;
    color: #ffffffbf;
  }
  &__wrapper {
    padding: 14px 30px;
    position: relative;
    color: #ffffffbf;
  }
  &__left-container {
    width: 50%;
  }
  &__top-section {
    padding: 0 16px 17px 16px;
    border-bottom: 1.5px solid #5a6672;
  }
  &__bottom-section {
    padding: 27px 16px;
  }
  &__title {
    color: #39ba96;
    font-weight: 700;
  }
  &__web {
    color: #91999f;
    margin-top: 5.5px;
  }
  &__desc {
    color: #ecedee;
    margin-top: 20px;
  }
  &__btn-container {
    margin-top: 25px;
    display: flex;
    position: relative;
  }
  &__button {
    width: 173px;
    text-align: center;
    border: 1px solid #39ba96;
    color: #39ba96;
    font-weight: 700;
    padding: 7px;
    border-radius: 4px;
    margin-right: 6px;
    &--menu {
      width: 43px;
      height: 100%;
      font-size: 18px;
      margin-right: 0;
      position: relative;
    }
  }
  &__button-text-menu {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__title-bottom {
    font-weight: 700;
    color: #ecedee;
  }
  &__video {
    margin-top: 17px;
  }
  &__menu-section {
    padding: 15px 25px;
    border-radius: 4px;
    border: 1px solid #39ba96;
    background-color: #1e2936;
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 7;
  }
  &__menu-item {
    color: #39ba96;
    font-size: var(--font-md);
    font-weight: 700;
    margin-top: 15px;
    width: 135px;
    justify-content: space-between;
    display: flex;
    padding: 0;
  }
  &__menu-item:first-child {
    margin-top: 0;
  }
  &__menu-item-img {
    height: 15px;
  }
  &__cover-img {
    position: absolute;
    right: 0;
    top: 40px;
    width: 40%;
    max-width: 650px;
  }
  &__layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 6;
  }
  &__menu-container {
    position: relative;
  }
  &__content {
    margin-top: 27px;
  }
  &__content-title {
    font-weight: 700;
  }
  &__content-desc {
    margin-top: 15px;
  }
  &__content-link {
    color: #39ba96;
    font-weight: 700;
  }
}

@media screen and (min-width: 1599px) {
  .game-detail {
    &__menu-section {
      padding: 20px 25px;
      top: 53px;
    }
    &__menu-item {
      width: 220px;
      margin-top: 20px;
    }
    &__menu-item-img {
      height: 25px;
    }
  }
}

@media screen and (max-width: 1366px) {
  .game {
    &__description {
      width: 70%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .game {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
    &__btn-container {
      margin-top: 25px;
      display: flex;
    }
    &__navigation {
      display: none;
    }
    &__wrapper {
      overflow: visible;
    }
  }
  .game-detail {
    &__cover-img {
      top: 80px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .game-detail {
    &__cover-img {
      width: 25%;
    }
    &__left-container {
      width: 75%;
    }
  }
}

@media screen and (max-width: 767px) {
  .game {
    &__main-container {
      padding: 20px 10px 0 15px;
    }
    &__list {
      grid-template-columns: repeat(1, 1fr);
    }
    &__navigation {
      display: none;
    }
    &__btn-container {
      display: flex;
    }
    &__button-item-mobile {
      width: 150px;
    }
    &__wrapper {
      overflow: visible;
    }
  }
  .game-detail {
    &__cover-img {
      top: 145px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .main-games {
    padding-top: 53px;
  }
}

@media screen and (max-width: 576px) {
  .game {
    &__description {
      width: 75%;
    }
    &__button-item-mobile {
      width: 150px;
    }
  }
}

@media screen and (max-width: 480px) {
  .main-games {
    padding-top: 0px;
  }
}

@media screen and (max-width: 414px) {
  .game {
    &__button-item-mobile {
      width: 110px;
    }
  }
}