.countdown-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 5px;

  .title {
    line-height: 150%;
    font-weight: $poppins-regular;
    color: $avo-grey-1;
    margin-right: 5px;
  }

  .timer {
    line-height: 150%;
    font-weight: $poppins-semi-bold;
    color: $avo-white;

    .time-number {
      font-size: 30px;
      color: $avo-white;
      margin-right: 2px;
      margin-left: 2px;
    }

    .time-label {
      font-size: 13px;
      font-family: $poppins-regular;
      font-weight: 100;
      color: $avo-grey-3;
      margin-right: 2px;
      margin-left: 2px;
      line-height: 0;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
}
