.prices-marquee {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: 23px;
    width: 100%;
    background-color: $avo-black-3;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  
    .prices-ticker-item {
        margin-left: 20px;
        margin-right: 20px;
        width: 130px;
        text-align: center;
    }
  }
  
  .prices-marquee-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 25;
    background: linear-gradient(
      90deg,
      rgba(22, 22, 23, 0.7) -0.6%,
      rgba(0, 0, 0, 0) 13.18%,
      rgba(3, 3, 3, 0.0496451) 79.38%,
      rgba(22, 22, 23, 0.69) 101.1%
    );
    pointer-events: none;
  }
  
  @media only screen and (max-width: 480px) {
    .prices-marquee {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-top: 10px;
      margin-bottom: 20px;

      .prices-ticker-item {
        margin-left: 10px;
        margin-right: 10px;
        width: 120px;
    }
    }
  }
  