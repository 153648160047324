.news-section {
  display: flex;
  justify-content: center;
  color: white;
  // margin-top: 80px;

  .readmore {
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    display: flex;
    font-weight: 600;
    width: 100px;

    img {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .line {
    width: 100% !important;
    height: 3px !important;
    margin: 10px 0;
  }

  .container {
    height: 600px;
    display: flex;
    justify-content: center;
    gap: 35px;

    .main-news {
      width: 480px;
      //   background-color: green;

      img {
        width: 100%;
        margin-bottom: 20px;
      }

      p {
        margin: 10px 0;
      }
    }

    .list-news {
      width: 820px;
      //   background-color: blue;
      display: flex;
      gap: 20px;
      flex-direction: column;

      img {
        width: 300px;
        height: 200px;
      }

      .news {
        display: flex;
        gap: 24px;

        .news-desc {
          display: flex;
          flex-direction: column;
        }
      }

      p {
        margin: 10px 0;
      }
    }
  }
}

@media only screen and (min-width: 2000px) {
  .news-section {
    .container {
      .main-news {
        width: 680px;
      }

      .list-news {
        img {
          width: 340px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1420px) {
  .news-section {
    .container {
      .list-news {
        width: 740px;
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .news-section {
    .container {
      .main-news {
        width: 440px;
      }

      .list-news {
        width: 550px;
        img {
          width: 240px;
          height: 180px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1025px) {
  .news-section {
    .container {
      flex-direction: column;
      align-items: center;
      height: auto;
      .main-news {
        width: 90%;
        padding-right: 10px;
      }

      .list-news {
        width: 90%;
        padding-right: 10px;

        img {
          width: 40%;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .news-section {
    .container {
      .list-news {
        .news {
          .news-desc {
            p {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .news-section {
    .container {
      .list-news {
        .news {
          .news-desc {
            h5 {
              font-size: 16px !important;
            }
            // p{
            //   font-size: 12px !important;
            // }
            .readmore {
              font-size: 12px !important;
            }
          }
        }
        img {
          width: 200px;
          height: 160px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .news-section {
    .container {
      .list-news {
        .news {
          .news-desc {
            h5 {
              font-size: 14px !important;
              overflow: hidden;
              line-height: 1.25rem;
              max-height: 8rem;
              -webkit-box-orient: vertical;
              display: block;
              display: -webkit-box;
              overflow: hidden !important;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
            }
            .readmore {
              font-size: 12px !important;
            }
          }
        }
        img {
          width: 150px;
          height: 125px;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .news-section {
    .container {
      .list-news {
        .news {
          .news-desc {
            h5 {
              font-size: 14px !important;
            }
            .readmore {
              font-size: 10px !important;
              align-items: center;
            }
            img {
              margin: 2.5px 0;
            }
          }
        }
        img {
          width: 120px;
          height: 100px;
        }
      }
    }
  }
}
