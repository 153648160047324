.partners-section {
  z-index: 20;

  .partners-heading {
    display: flex;
    z-index: 20;
  }

  .text-wrap {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-bottom: 40px;
    text-align: center;
    z-index: 20;
  }

  .partners-list {
    max-width: 100%;
  }

  .partner-logo {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    z-index: 20;
  }

  .partner-logo-scroll {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    z-index: 20;
  }

  .partners-marquee {
    margin-bottom: 10px;
    background-color: transparent;
  }
}

@media only screen and (max-width: 1180px) {
  .partners-section {
    .partner-logo {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .partners-section {
    .text-wrap {
      max-width: 340px;
    }
  }
}
