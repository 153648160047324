// Poppins
// =============================================================

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

// Furore
// =============================================================

@font-face {
  font-family: 'Furore';
  src: url('../../fonts/Furore-webfont.woff') format('truetype');
  font-weight: 900;
  font-style: normal;
}

// Gilroy
// =============================================================

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../../fonts/Gilroy-Bold.woff') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../../fonts/Gilroy-Regular.woff') format('truetype');
  font-weight: 900;
  font-style: normal;
}

// SF-Pro
// =============================================================

// Open Sans
// =============================================================

@font-face {
  font-family: 'Open Sans';
  src: url('../../fonts/OpenSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}