.vaults-section {
  display: flex;
  justify-content: center;
  color: white;
  position: relative;
  z-index: 9999;
  margin-top: -4vw;

  .section-title {
    text-align: center;
    margin: 20px 0px;
    font-size: 50px;
    line-height: 50px;
    font-weight: 600;
    color: #ffffff;
  }

  .text-wrap {
    display: flex;
    flex-direction: column;
    max-width: 40vw;
    margin-bottom: 4vw;
    text-align: center;
    z-index: 20;

    p {
      font-size: 16px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: auto;
  }

  .all-vaults-btn {
    margin: 0 auto;
  }

  .vault-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2vw;

    .pool-name {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 36px 34px 0 34px;
      margin-right: 20px;
      margin-top: 12px;
      margin-bottom: 14px;
      width: 250px;
      height: 300px;
      background-image: url("../../images/vaults/green-box.svg");
      background-size: contain;
      background-repeat: no-repeat;

      @media only screen and (min-width: 1024px) {
        padding: 2.5vw 2.3611111vw 0 2.3611111vw;
        margin-right: 1.388888vw;
        margin-top: 0.83333vw;
        margin-bottom: 0.9722222vw;
        width: 17.36111vw;
        height: 20.83333vw;
      }

      .pool-title {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen and (min-width: 1024px) {
          margin-top: 0.83333vw;
        }

        label {
          font-family: "Furore";
          font-style: normal;
          font-weight: 400;
          font-size: 16.6346px;
          line-height: 17px;
          text-align: center;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          color: #849e16;

          @media only screen and (min-width: 1024px) {
            font-size: 1.155vw;
            line-height: 1.1805555vw;
          }
        }

        span {
          font-family: Gilroy-Regular;
          font-size: 8px;
          line-height: 8px;
          display: flex;
          align-items: center;
          text-transform: capitalize;
          color: #b6f7d1;
          margin-top: 5px;
          cursor: pointer;

          @media only screen and (min-width: 1024px) {
            font-size: 0.5555555vw;
            line-height: 0.5555555vw;
            margin-top: 0.347222vw;
          }

          a {
            color: #b6f7d1;
            margin: 0px 0px 0px 3px;
            font-size: 0.55vw;
          }

          @media only screen and (min-width: 1024px) {
            font-size: 0.56vw;
            line-height: 0.56vw;
            margin-top: 0.35vw;
          }
        }
      }

      .pool-apy {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0 8px 0;

        @media only screen and (min-width: 1024px) {
          margin: 0.83333vw 0 0.56vw 0;
        }

        img {
          width: 180px;
          height: 6px;

          @media only screen and (min-width: 1024px) {
            width: 12.5vw;
            height: 0.4166666vw;
          }
        }

        span {
          font-family: Gilroy-Bold;
          font-style: normal;
          font-weight: 800;
          font-size: 28.2788px;
          line-height: 35px;
          text-align: center;
          letter-spacing: 0.04em;
          color: #b6f7d1;
          margin-top: 4px;

          @media only screen and (min-width: 1024px) {
            font-size: 1.9555555vw;
            line-height: 2.43055555vw;
            margin-top: 0.2777777vw;
          }
        }

        p {
          font-family: Gilroy-Bold;
          font-style: normal;
          font-weight: 800;
          font-size: 8px;
          line-height: 10px;
          text-align: center;
          text-transform: uppercase;
          color: #b6f7d1;
          margin-bottom: 8px;

          @media only screen and (min-width: 1024px) {
            font-size: 0.5555555vw;
            line-height: 0.69444444vw;
            margin-bottom: 0.5555555vw;
          }
        }
      }

      .tvl-progress {
        display: flex;
        flex-direction: column;
        margin-bottom: 15.64px;

        @media only screen and (min-width: 1024px) {
          margin-bottom: 1.0888vw;
        }

        .tvl-label {
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;

          @media only screen and (min-width: 1024px) {
            margin-bottom: 0.2777777vw;
          }

          span {
            font-family: "Gilroy-Bold";
            font-size: 8px;
            line-height: 140%;
            text-align: right;
            color: #ffffff;

            @media only screen and (min-width: 1024px) {
              font-size: 0.5555555vw;
            }
          }
        }
      }

      .pool-item {
        display: flex;
        justify-content: space-between;

        .label {
          display: flex;
          flex-direction: column;

          label {
            font-family: Gilroy-Bold;
            font-style: normal;
            font-weight: 800;
            font-size: 8px;
            line-height: 120%;
            color: #ffffff;
            margin-top: 9px;

            @media only screen and (min-width: 1024px) {
              font-size: 0.5555555vw;
              margin-top: 0.625vw;
            }
          }

          label:first-child {
            margin-top: 0;
          }
        }

        .data {
          display: flex;
          flex-direction: column;
          text-align: end;

          span {
            font-family: Gilroy-Bold;
            font-style: normal;
            font-weight: 700;
            font-size: 8px;
            line-height: 120%;
            text-align: right;
            color: #ffffff;
            margin-top: 9px;

            @media only screen and (min-width: 1024px) {
              font-size: 0.5555555vw;
              margin-top: 0.625vw;
            }
          }

          span:first-child {
            margin-top: 0;
          }
        }
      }
    }

    .pool-name:nth-child(4) {
      margin-right: 0;
    }

    .pool-name.purple {
      background-image: url("../../images/vaults/purple-box.svg");

      .pool-title.purple {
        label {
          color: #d56ff9;
        }

        span {
          color: #d46cf9;

          a {
            color: #d46cf9;
            margin: 0px 0px 0px 3px;
            font-size: 0.55vw;

            @media only screen and (max-width: 768px) {
              margin: 0px 0px 0px 0.5vw;
              font-size: 1.65vw;
            }
          }
        }
      }

      .pool-apy.purple {
        span {
          color: #d56ff9;
        }

        p {
          color: #d46cf9;
        }
      }
    }

    .pool-name.gold {
      background-image: url("../../images/vaults/gold-box.svg");

      .pool-title.gold {
        label {
          color: #f9c26f;
        }

        span {
          color: #f5970a;

          a {
            color: #f5970a;
            margin: 0px 0px 0px 3px;
            font-size: 0.55vw;

            @media only screen and (max-width: 768px) {
              margin: 0px 0px 0px 0.5vw;
              font-size: 1.65vw;
            }
          }
        }
      }

      .pool-apy.gold {
        span {
          color: #fbd69d;
        }

        p {
          color: #fbd69d;
        }
      }
    }

    .pool-name.blue {
      background-image: url("../../images/vaults/blue-box.svg");

      .pool-title.blue {
        label {
          color: #75b3f0;
        }

        span {
          color: #1980e6;

          a {
            color: #1980e6;
            margin: 0px 0px 0px 3px;
            font-size: 0.55vw;

            @media only screen and (max-width: 768px) {
              margin: 0px 0px 0px 0.5vw;
              font-size: 1.65vw;
            }
          }
        }
      }

      .pool-apy.blue {
        span {
          color: #75b3f0;
        }

        p {
          color: #75b3f0;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .vaults-section {
    margin: 0;
    padding: 0px 30px;

    .section-title {
      font-size: 4.55vw !important;
      line-height: 20px !important;
    }

    .text-wrap {
      max-width: 90% !important;

      p {
        font-size: 14px !important;
      }
    }

    .vault-list {
      flex-direction: column;

      .pool-name {
        width: 70vw;
        height: 85vw;
        margin: 0px auto 4.5vw auto;
        padding: 10vw 10vw 0 10vw;

        .pool-title {
          width: 100%;
          margin-top: 5vw;

          label {
            font-size: 4.5vw;
            line-height: 110%;
            letter-spacing: 0.04em;
          }

          span {
            font-size: 2vw;
            line-height: 100%;
            margin-top: 5px;

            a {
              margin: 0px 0px 0px 0.5vw;
              font-size: 1.65vw;
            }
          }
        }

        .pool-apy {
          width: 100%;
          margin: 3vw 0;

          img {
            width: 50vw;
            height: 1.5vw;
          }

          span {
            font-size: 8vw;
            line-height: 140%;
            letter-spacing: 0.04em;
            margin-top: 1vw;
          }

          p {
            font-size: 2vw;
            line-height: 120%;
            margin-bottom: 2vw;
          }
        }

        .tvl-progress {
          width: 100%;
          margin-bottom: 4vw;

          .tvl-label {
            margin-bottom: 1vw;

            span {
              font-size: 2vw;
            }
          }
        }

        .pool-item {
          .label {
            label {
              font-size: 2vw;
              margin-top: 2.2vw;
            }
          }

          .data {
            span {
              font-size: 2vw;
              margin-top: 2.2vw;
            }
          }
        }
      }
    }
  }

  .container {
    flex: 0 0 100%;
    justify-content: center;
    max-width: 100%;
    min-height: 80vw;
    margin: 11vw 11vw;
  }
}

// @media only screen and (max-width: 480px) {
//   .vaults-section {
//     margin: 0;

//     .section-title {
//       font-size: 4.55vw !important;
//     }

//     .text-wrap {
//       max-width: 90% !important;

//       p {
//         font-size: 12px;
//       }
//     }

//     .container {
//       // gap: 74vw;
//       width: 95%;
//       display: inline-flex;

//       .vault-list {
//         padding: 5vw 0;
//         display: inline-block;

//         .pool-name {
//           width: 100%;
//           height: 475px;
//           padding: 45px;

//           .pool-apy {
//             margin: 12px 0;
//           }

//           .pool-item {
//             padding: 0 12px;

//             label {
//               font-family: Gilroy-Regular;
//             }
//           }
//         }
//       }
//     }
//   }
// }

@media only screen and (max-width: 1420px) {
  .vaults-section {
    margin-top: 0vw;

    .section-title {
      margin: 20px 0px;
      font-size: 4.2vw;
      line-height: 3.55vw;
    }

    .text-wrap {
      max-width: 60%;

      p {
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: 2500px) {
  .vaults-section {
    margin-top: 0vw;
  }
}

.bar-progress {
  div {
    height: 0.3vw !important;

    @media only screen and (max-width: 768px) {
      height: 1.5vw !important;
    }
  }
}
