.prices-table {
  width: 100%;
  background-color: $avo-black-3;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-bottom: 40px;

  th {
    text-align: left;
    padding-top: 23px;
    padding-bottom: 23px;
    padding-left: 28px;
    padding-right: 28px;
  }
  td {
    text-align: left;
    padding-top: 23px;
    padding-bottom: 23px;
    padding-left: 28px;
    padding-right: 28px;
    border-top: 1px solid $avo-grey-6;
    height: 56px;
  }

  .price-table-header {
    color: $avo-grey-3;
  }

  .token-name {
    font-weight: $poppins-semi-bold;
    
  }

}

@media only screen and (max-width: 480px) {
  .prices-table {
    margin-bottom: 20px;

    th {
      padding-left: 12px;
      padding-right: 12px;
    }
    td {
      padding: 12px;
    }

    .token-name {
      font-weight: $poppins-semi-bold;
      font-size: 12px;
    }

    .token-balance {
      font-size: 12px;
    }
  
    .token-balance-price {
      font-size: 12px;
    }  
  }
}
