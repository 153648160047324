.graph-section {
  z-index: 100;
  margin-left: 50px;
  margin-right: 50px;

  .graph-container {
    .graph {
      width: 100%;
      border-radius: 20px;
      display: flex;
    }

    .graph-mobile {
      display: none;
    }
  }
}

@media only screen and (min-width: 1201px) {
}

@media only screen and (min-width: 1600px) {
  .graph-section {
    z-index: 100;
    margin-left: 50px;
    margin-right: 50px;

    .graph-container {
      align-self: center;

      .graph {
        width: 100%;
      }
    }
  }
}
@media only screen and (min-width: 1800px) {
  .graph-section {
    z-index: 100;
    margin-left: 50px;
    margin-right: 50px;

    .graph-container {
      align-self: center;
      width: 90%;

      .graph {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 2000px) {
  .graph-section {
    z-index: 100;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;

    .graph-container {
      max-width: 2000px;
      align-self: center;

      .graph {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .graph-section {
    z-index: 100;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;

    .graph-container {
      width: 80%;
      align-self: center;

      .graph {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 480px) {
  .graph-section {
    z-index: 100;
    margin-left: 0px;
    margin-right: 0px;

    .graph-container {
      .graph {
        display: none;
      }
      width: 90%;

      .graph-mobile {
        display: flex;
        width: 100%;

        .tokenomics {
          width: 100%;
          height: auto;
          cursor: pointer;
        }
        .token-supply {
          margin-top: 20px;
          border-radius: 8px;
          width: 100%;
          height: auto;
          cursor: pointer;
        }
        .token_value_accrual {
          margin-top: 20px;
          border-radius: 8px;
          width: 100%;
          height: auto;
          cursor: pointer;
        }
        .graph-1 {
          margin-top: 20px;
          width: 100%;
          height: auto;
          cursor: pointer;
        }

        .graph-2 {
          margin-top: 20px;
          width: 100%;
          height: auto;
          cursor: pointer;
        }
        .graph-3 {
          margin-top: 20px;
          width: 100%;
          height: auto;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 320px) {
}
