.custom-marquee {
  width: 100%;
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    .cards-wrap {
      flex: 0 0 auto;
      width: 100%;
      max-width: 100%;
      position: relative;
      background: blue;
      height: 180px;
      overflow: hidden;
    }

    .card {
      background: purple;
      width: 140px;
      height: 140px;
      display: inline-block;
      position: absolute;
      margin-top: 20px;
      left: -160px;
      color: white;
      font-size: 30px;
      text-align: center;
      line-height: 70px;
    }
  }
}
