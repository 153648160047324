.metaverse-section {
  z-index: 20;
  margin-bottom: 50px;

  @media only screen and (min-width: 1200px) {
    top: -180px;
    position: relative;
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 1920px) {
    top: -230px;
  }

  #metaverse-mobile,
  #metaverse-desktop {
    flex-direction: column;
    width: 100%;
  }

  #metaverse-mobile {
    display: flex;

    @media only screen and (min-width: 768px) {
      display: none;
    }

    // .honeycomb-odd {
    //   &.honeycomb-odd--center {
    //     li {
    //       &:nth-child(-n + 8) {
    //         display: none;
    //       }
    //     }
    //   }
    // }
  }
  #metaverse-desktop {
    display: none;

    @media only screen and (min-width: 768px) {
      display: flex;
    }
  }

  .metaverse-heading {
    display: flex;
    z-index: 20;
    font-family: Gilroy-Bold;
    // font-size: 50px;
    // line-height: 59px;
    letter-spacing: 0px;
    text-align: center;
  }

  .text-wrap {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-bottom: 40px;
    text-align: center;
    z-index: 20;

    p {
      font-family: Gilroy-Regular;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 0.02em;
      text-align: center;
    }
  }
}

.honeycomb {
  display: -webkit-box;
  display: flex;

  list-style: none;
  -webkit-box-pack: center;

  -webkit-box-align: center;

  max-width: calc(100vw + 10vw);
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  width: 100%;

  &.overflow-x-auto {
    overflow-x: auto;
    height: 380px;
    justify-content: center;

    @media only screen and (min-width: 768px) {
      overflow-x: unset;
      height: 100%;
      justify-content: flex-start;
    }
  }

  /* Scrollbar */
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    margin-left: 10px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    // background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.37);
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 0.35);
  }

  .honeycomb-odd,
  .honeycomb-even {
    // li {
    //   &:nth-child(-n + 6),
    //   &:nth-last-child(-n + 3) {
    //     display: none;

    //     @media only screen and (min-width: 2500px) {
    //       display: flex;
    //     }
    //   }

    //   &:nth-child(1),
    //   &:nth-child(2),
    //   &:nth-child(3) {
    //     @media only screen and (min-width: 1200px) {
    //       &:first-child {
    //         display: flex;
    //       }
    //     }

    //     @media only screen and (min-width: 1920px) {
    //       display: flex;
    //     }
    //   }
    // }
  }
}

.honeycomb-cell {
  -webkit-box-flex: 0;
  flex: 0 0 80px;
  max-width: 255px;
  height: 80px;
  margin: 0 -6px 0;
  position: relative;
  padding: 0.5em;
  text-align: center;
  z-index: 1;
  //   box-shadow: 0px 0px 15px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    flex: 0 0 105px;
    height: 105px;
    margin: 0 -7px 0;
  }

  @media only screen and (min-width: 1024px) {
    flex: 0 0 145px;
    height: 145px;
    margin: 0 -10px 0;
  }

  @media only screen and (min-width: 1200px) {
    flex: 0 0 165px;
    height: 165px;
    margin: 0 -11px 0;
  }

  &:hover {
    .honeycomb-img-hover {
      transform: scale(1.2);
      opacity: 1;
      z-index: 4;

      ~ span {
        opacity: 1;
        z-index: 4;
      }
    }
    .honeycomb-img-partner {
      transform: scale(1.2);
      opacity: 0;
    }
    .honeycomb-img-arrow {
      opacity: 0.9;
    }
  }
}
.honeycomb-cell_img {
  object-fit: cover;
  object-position: center;
  /* filter: grayscale(100%); */
}
.honeycomb-cell_title {
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  font-size: 1.75em;
  transition: opacity 350ms;
}
.honeycomb-cell_title > small {
  font-weight: 300;
  margin-top: 0.25em;
}
.honeycomb-cell::before,
.honeycomb-cell::after {
  content: "";
}
.honeycomb-cell::before,
.honeycomb-cell::after,
.honeycomb-cell_img {
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  /* -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
  /* clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%); */
  z-index: -1;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.honeycomb-cell::before {
  background: #fff;
  transform: scale(1.055);
  opacity: 0;
}
.honeycomb-cell::after {
  background: transparent;
  opacity: 0;
  transition: opacity 350ms;
  -webkit-transition: opacity 350ms;
}
.honeycomb-cell:hover .honeycomb-cell_title {
  opacity: 0;
}
.honeycomb-cell:hover .honeycomb-cell_img {
  filter: grayscale(0%);
}
.honeycomb-cell:hover::before {
  background: #fc5130;
}
.honeycomb-cell:hover::after {
  opacity: 0;
}
.honeycomb_Hidden {
  display: none;
  opacity: 0;
  width: 250px;
  margin: 0 12.5px;
}

/****** Responsive *******/

// @media (max-width: 550px) {
//   .honeycomb-cell {
//     margin: 81.25px 25px;
//   }
// }

// @media (min-width: 550px) and (max-width: 825px) {
//   .honeycomb-cell:nth-child(3n) {
//     margin-right: calc(50% - 125px);
//     margin-left: calc(50% - 125px);
//   }

//   .honeycomb_Hidden:nth-child(3n + 5) {
//     display: block;
//   }
// }

// @media (min-width: 825px) and (max-width: 1100px) {
//   .honeycomb-cell:nth-child(5n + 4) {
//     margin-left: calc(50% - 275px);
//   }

//   .honeycomb-cell:nth-child(5n + 5) {
//     margin-right: calc(50% - 275px);
//   }

//   .honeycomb_Hidden:nth-child(5n),
//   .honeycomb_Hidden:nth-child(5n + 3) {
//     display: block;
//   }
// }
// @media (min-width: 1100px) {
//   .honeycomb-cell:nth-child(7n + 5) {
//     /* margin-left: calc(50% - 400px); */
//   }

//   .honeycomb-cell:nth-child(7n + 7),
//   .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) {
//     /* margin-right: calc(50% - 400px); */
//   }

//   .honeycomb_Hidden:nth-child(7n + 7),
//   .honeycomb_Hidden:nth-child(7n + 9),
//   .honeycomb_Hidden:nth-child(7n + 11) {
//     display: block;
//   }
// }

.honeycomb-img-wrapper {
  width: 45px;

  @media only screen and (min-width: 768px) {
    width: 55px;
  }

  @media only screen and (min-width: 1024px) {
    width: 80px;
  }

  @media only screen and (min-width: 1200px) {
    width: 95px;
  }

  .honeycomb-img-partner {
    transition: transform 0.5s ease;
    width: 100%;
    height: 100%;
  }
}

.honeycomb-img-arrow-wrapper {
  width: 18px;
  cursor: pointer;

  @media only screen and (min-width: 768px) {
    width: 36px;
  }

  &.honeycomb-img-arrow-wrapper--arrow-left {
    transform: rotate(180deg);
  }

  .honeycomb-img-arrow {
    transition: opacity 0.5s ease, filter 0.5s ease;
    width: 100%;
    height: 100%;

    &.honeycomb-img-arrow--inactive {
      filter: grayscale(100%);
    }
  }
}

.honeycomb-img-hover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0;
  display: block;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  z-index: 4;
  object-fit: cover;
  object-position: center;

  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0;

  ~ span {
    opacity: 0;
    position: absolute;
    font-family: Gilroy-Bold;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;

    @media only screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 24px;
    }

    @media only screen and (min-width: 1024px) {
      font-size: 24px;
      line-height: 30px;
    }

    @media only screen and (min-width: 1200px) {
      font-size: 30px;
      line-height: 36px;
    }
  }
}

.honeycomb.honeycomb-odd {
  // margin-left: 10px;
  flex-wrap: nowrap;

  &:not(:nth-of-type(1)) {
    margin-top: -20px;
  }

  @media only screen and (min-width: 768px) {
    // margin-left: 40px;

    &:not(:nth-of-type(1)) {
      margin-top: -27px;
    }
  }

  @media only screen and (min-width: 1024px) {
    // margin-left: -17px;

    &:not(:nth-of-type(1)) {
      margin-top: -38px;
    }
  }

  @media only screen and (min-width: 1200px) {
    // margin-left: -17px;
    // margin-left: -67px;

    &:not(:nth-of-type(1)) {
      margin-top: -42px;
    }
  }

  @media only screen and (min-width: 1920px) {
    // margin-left: 33px;
  }
}

.honeycomb.honeycomb-even {
  // margin-left: -37px;
  margin-top: -20px;
  flex-wrap: nowrap;

  @media only screen and (min-width: 768px) {
    // margin-left: -24px;
    margin-top: -27px;
  }

  @media only screen and (min-width: 1024px) {
    // margin-left: -124px;
    // margin-left: -107px;
    margin-top: -38px;
  }

  @media only screen and (min-width: 1200px) {
    // margin-left: -174px;
    margin-top: -42px;
  }

  @media only screen and (min-width: 1920px) {
    // margin-left: -74px;
  }
}
