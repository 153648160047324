// FONT SIZE
:root {
  --font-md: 14px;
  --font-lg: 18px;
  --font-3xl: 30px;
}

@media screen and (min-width: 1366px) {
  :root {
    --font-md: 12px;
    --font-lg: 16px;
    --font-3xl: 28px;
  }
}

@media screen and (min-width: 1599px) {
  :root {
    --font-md: 16px;
    --font-lg: 20px;
    --font-3xl: 32px;
  }
}

@media screen and (min-width: 1919px) {
  :root {
    --font-md: 20px;
    --font-lg: 24px;
    --font-3xl: 36px;
  }
}

@media screen and (min-width: 2399px) {
  :root {
    --font-md: 24px;
    --font-lg: 28px;
    --font-3xl: 40px;
  }
}


@media screen and (min-width: 3199px) {
  :root {
    --font-md: 28px;
    --font-lg: 32px;
    --font-3xl: 44px;
  }
}

@media screen and (min-width: 3599px) {
  :root {
    --font-md: 32px;
    --font-lg: 36px;
    --font-3xl: 48px;
  }
}

@media screen and (min-width: 3999px) {
  :root {
    --font-md: 36px;
    --font-lg: 40px;
    --font-3xl: 52px;
  }
}

// FONT CLASS
.fs-12 {
  font-size: var(--font-md) !important;
}
.fs-16 {
  font-size: var(--font-lg) !important;
}
.fs-24 {
  font-size: var(--font-3xl) !important;
}

button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

a:hover, a:focus, a:active {
  color: #39ba96 !important;
  text-decoration: none;
  cursor: pointer;
}
