.page-news {
  padding-top: 82px;
  &__container {
    padding: 6px 60px 30px;
    background-color: white;
  }
}

@media only screen and (max-width: 480px) {
  .page-news {
    padding-top: 0;
  }
}