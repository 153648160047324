.news-item {
  &__container {
    padding: 20px;
    background-color: $avo-light-grey-2;
    border: 1px solid $avo-light-grey;
    border-radius: 9px;
    text-align: center;
    margin-right: 26px;
    width: calc(25% - 26px);
    max-width: 300px;
    min-width: 200px;
    margin-top: 24px;
    cursor: pointer;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 18px;
    text-decoration: none;
    color: black;
    font-family: Poppins;
  }
  &__image {
    width: 100%;
  }
}