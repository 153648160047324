.guild-section {
  z-index: 4;
  margin-top: 109px;
  margin-left: 50px;

  .green-bullet {
    width: 12px;
    height: 12px;
    background-color: $avo-green-1;
    border-radius: 100%;
    align-self: center;
    margin-right: 18px;
  }

  .guild-objects {
    position: relative;

    .green-planet-1 {
      z-index: 0;
      position: absolute;
      width: 9vw;
      height: auto;
      top: 8vh;
      left: 16vw;
    }

    .green-planet-2 {
      z-index: 0;
      position: absolute;
      width: 9vw;
      height: auto;
      right: 22vw;
      top: 36vh;
    }
  }

  .portal {
    position: absolute;
    left: -25vw;
    width: 75vw;
    height: auto;
    top: 0vw;
  }

  .spaceship {
    position: absolute;
    width: 35vw;
    height: auto;
    left: 5vw;
    top: 35vw;
  }

  .guild-heading {
    z-index: 10;
    margin-top: 14vh;
    margin-left: 20vw;
    width: 55%;

    &.token-sale-heading {
      position: relative;
      margin-left: 0;
      width: 100%;
      margin-top: 200px;
      // margin-bottom: 200px;
      margin-bottom: 64px;
      // background-image: url('../../images/video-bg.svg');
      // background-size: contain;
      // background-repeat: no-repeat;
    
    }

    .video-placeholder {
      position: relative;
      width: 45%;
      border-radius: 8px;
      overflow: hidden;
      filter: drop-shadow(0px 0px 100px #4f4f4f);

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        filter: drop-shadow(0px 0px 100px #4f4f4f);
        align-self: center;
        border-radius: 8px;
      }

      .play-button {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 83.3px;
        height: 83.3px;
        aspect-ratio: 1;

        border-radius: 7px;
      }
    }

    .guild-heading-column {
      display: flex;
      align-self: stretch;
      margin-left: 58px;
      width: 40%;
      justify-content: space-between;
    }

    .guild-body {
      padding-left: 98px;
      max-width: 33vw;

      &.spacing {
        margin-top: 40px;
      }

      &.token-sale {
        padding-left: 0;
        max-width: fit-content;
        margin-top: 20px;
      }

      .btn-hero {
        padding: 12px 20px;
      }

      .btn-discord {
        &.spacing {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      .element {
        text-align: left;
        &.spacing {
          margin-bottom: 30px;
        }

        &.token-sale-element {
          font-size: 16px;
        }
      }
    }

    .guild-stats {
      justify-content: space-between;
      flex-direction: row;
      max-width: 80vw;

      &.spacing {
        margin-left: 98px;
        margin-top: 40px;
      }

      &.token-sale {
        margin-left: 0;
        margin-top: 10px;
      }

      .guild-stats-element {
        &.spacing {
          margin-bottom: 40px;
        }

        &.token-sale {
          margin-bottom: 0;
        }

        .numbers {
          font-size: 50px;

          &.token-sale-numbers {
            // font-size: 24px;
            font-size: 42px;
          }
        }
      }
    }

    &.spacing {
      margin-bottom: 24.17px;
    }

    .buttons-row {
      .header-white {
        font-size: 16px;
        color: $avo-white;

        &.spacing {
          margin-left: 20px;
        }
      }

      a .header-white-paper {
        text-decoration: underline;
        font-size: 16px;

        &.spacing {
          margin-left: 20px;
        }
      }
    }
  }

  .headline {
    &.spacing {
      margin-bottom: 20px;
    }
  }

  .guild-header-title {
    font-size: 36px;
    line-height: 120%;
    max-width: 720px;
    margin-bottom: 0;
  }

  .happening-now {
    color: $avo-white;
    font-size: 32px;
  }

  .countdown {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 150%;
    color: $avo-grey-2;

    .time {
      color: $avo-white;
      margin-left: 10px;
    }
  }
}

@media only screen and (min-width: 1201px) {
  .guild-section {
    // margin-bottom: 60px;

    &.token-sale-section {
      margin-top: 100px;
      padding-top: 100px;
      // margin-bottom: 200px;
      margin-bottom: 60px;
    }

    .portal {
      width: 1080px;
      left: -386px;
    }
    .spaceship {
      top: 500px;
      left: 90px;
      width: 502px;
    }

    .guild-heading {
      margin-top: 80px;

      &.token-sale-heading {
        position: relative;
        margin-left: 0;
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0;
      }

      .guild-body {
        .element {
          text-align: left;
          font-size: 1.5rem;
          line-height: 120%;

          &.spacing {
            margin-bottom: 20px;
          }

          &.token-sale-element {
            font-size: 18px;
          }
        }
      }
      .guild-stats {
        &.token-sale {
          max-width: 100%;
        }

        .guild-stats-element {
          &.spacing {
            margin-bottom: 40px;
          }

          &.token-sale {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .guild-section {

    .guild-header-title {
      font-size: 48px;
      line-height: 120%;
      max-width: 720px;
      margin-bottom: 0;
    }

    &.token-sale-section {
      margin-top: 100px;
      padding-top: 0px;
      margin-bottom: 64px;
      align-items: center;
      align-self: center;
      margin-right: 50px;
    }

    .spaceship {
      &.token-sale-spaceship {
        top: 250px;
      }
    }

    .guild-heading {
      &.token-sale-heading {
        position: relative;
        margin-left: 0;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        max-height: 500px;
        align-items: center;
        align-self: center;
      }

      .video-placeholder {
        position: relative;
        max-width: 800px;
        width: 100%;
        height: auto;
        background-color: blue;
        border-radius: 8px;
        overflow: hidden;
        filter: drop-shadow(0px 0px 100px #4f4f4f);


        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          filter: drop-shadow(0px 0px 100px #4f4f4f);
          align-self: center;
          border-radius: 8px;
        }

        a .play-button {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 83.3px;
          height: 83.3px;
          aspect-ratio: 1;

          border-radius: 7px;
        }
      }

      .guild-heading-column {
        max-width: 800px;
        width: 100%;

        .guild-body {
          &.token-sale {
            max-width: 80%;
          }
          .element {
            &.token-sale-element {
              font-size: 22px;
            }
          }
        }
        .guild-stats {
          &.token-sale {
            max-width: 100%;
          }

          .guild-stats-element {
            &.spacing {
              margin-bottom: 40px;
            }

            &.token-sale {
              margin-bottom: 0;
            }

            .headline {
              &.token-sale-headline {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 2000px) {
  .guild-section {
    &.token-sale-section {
      margin-top: 100px;
      padding-top: 100px;
      margin-bottom: 200px;
    }
    .guild-heading {
      width: 60%;
      &.token-sale-heading {
        max-height: 500px;
      }

      .buttons-row {
        a .header-white-paper {
          font-size: 20px;

          &.spacing {
            margin-left: 40px;
          }
        }
      }

      .video-placeholder {
        position: relative;
        max-width: 1000px;
        border-radius: 8px;
        overflow: hidden;
        filter: drop-shadow(0px 0px 100px #4f4f4f);

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          filter: drop-shadow(0px 0px 100px #4f4f4f);
          align-self: center;
          border-radius: 8px;
        }

        a .play-button {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 83.3px;
          height: 83.3px;
          aspect-ratio: 1;

          border-radius: 7px;
        }
      }

      .guild-heading-column {
        max-width: 1000px;
        margin-left: 10%;
      }

      .guild-body {
        max-width: 50vw;
        width: 60%;

        &.spacing {
          margin-top: 80px;
        }
        &.token-sale {
          padding-left: 0;
          margin-top: 20px;
          max-width: 60%;
          width: 100%;
        }

        .element {
          text-align: left;
          font-size: 2rem;
          line-height: 130%;

          &.spacing {
            margin-bottom: 20px;
          }

          &.token-sale-element {
            font-size: 32px;
          }
        }
      }
    }

    .guild-stats {
      &.token-sale {
        width: 100%;
      }

      .guild-stats-element {
        &.spacing {
          margin-bottom: 40px;
        }

        &.token-sale {
          margin-bottom: 0;
        }

        .numbers {
          &.token-sale-numbers {
            font-size: 64px !important;
            margin-bottom: 20px;
          }
        }

        .headline {
          &.token-sale-headline {
            font-size: 32px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .guild-section {
    padding-bottom: 100px;

    &.token-sale-section {
      margin-right: 50px;
      padding-bottom: 10px;
    }

    .guild-heading {
      &.token-sale-heading {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
      }

      &.token-sale-heading-small {
        flex-direction: column;
      }

      .video-placeholder {
        position: relative;
        align-self: center;
        width: 80%;
        border-radius: 8px;
        overflow: hidden;
        filter: drop-shadow(0px 0px 100px #4f4f4f);

        img {
          object-fit: cover;

          height: 100%;
          width: 100%;
          // height: min-content;
          filter: drop-shadow(0px 0px 100px #4f4f4f);
          align-self: center;
          border-radius: 8px;
        }

        a .play-button {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 83.3px;
          height: 83.3px;
          aspect-ratio: 1;

          border-radius: 7px;
        }
      }

      .guild-heading-column {
        display: flex;
        align-self: center;
        margin-left: 58px;
        width: 80%;
        justify-content: space-between;
        margin-top: 60px;
      }

      .guild-body {
        padding-left: 0px;
        max-width: 100%;

        .btn-discord {
          &.spacing {
            margin-top: 0px;
            margin-bottom: 20px;
          }
        }

        .element {
          text-align: left;
          &.spacing {
            margin-bottom: 20px;
          }
        }
      }

      .buttons-row {
        .header-row {
          margin-top: 20px;
          .header-white {
            font-size: 16px;
            color: $avo-white;

            &.spacing {
              margin-left: 0;
            }
          }
        }
      }

      .guild-stats {
        display: flex;

        &.spacing {
          margin-left: 0;
          margin-top: 0;
        }

        &.token-sale {
          margin-top: 20px;
        }

        .guild-stats-element {
          &.spacing {
            margin-bottom: 20px;
            margin-right: 20px;
          }

          .numbers {
            font-size: 32px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .guild-section {
    margin-left: 50px;
    margin-bottom: 0;

    .guild-objects {
      position: relative;

      .green-planet-1 {
        z-index: 0;
        position: absolute;
        width: 9vw;
        height: auto;
        top: 8vh;
        left: 16vw;
      }

      .green-planet-2 {
        z-index: 0;
        position: absolute;
        width: 9vw;
        height: auto;
        right: 22vw;
        top: 36vh;
      }
    }

    .portal {
      position: absolute;
      left: -25vw;
      width: 75vw;
      height: auto;
      top: 20vw;
    }

    .spaceship {
      position: absolute;
      width: 35vw;
      height: auto;
      left: 5vw;
      top: 50vw;
    }

    .guild-heading {
      z-index: 10;
      margin-top: 14vh;
      margin-left: 10vw;
      width: 70%;

      .guild-body {
        padding-left: 9vw;
        max-width: 100%;
        width: 80%;

        &.spacing {
          margin-top: 3vh;
        }

        .element {
          text-align: left;
          &.spacing {
            margin-bottom: 2vh;
          }
        }
      }

      .guild-stats {
        justify-content: space-between;
        flex-direction: row;

        &.spacing {
          margin-left: 9vw;
          margin-top: 3vh;
        }

        .guild-stats-element {
          &.spacing {
            margin-right: 4vw;
            margin-top: 5vh;
          }
        }
      }

      &.spacing {
        margin-bottom: 0;
      }
    }

    .headline {
      &.spacing {
        margin-bottom: 20px;
      }
    }

    .guild-header-title {
      line-height: 130%;
      margin-bottom: 0;
      font-size: 32px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .guild-section {
    margin-top: 0;
    margin-top: 0;
    padding-bottom: 3vh;
    margin-left: 0;
    z-index: 10;

    .green-bullet {
      width: 10px;
      height: 10px;
      margin-right: 12px;
    }

    &.token-sale-section {
      padding-top: 0;
      margin-right: 0px;
      margin-bottom: 50px;
    }

    .guild-objects {
      position: relative;
      top: 0;
      left: 0;

      &.token-sale-objects {
        // top: 230px;
        top: 200px;
        left: -10px;
      }

      .green-planet-1 {
        z-index: 0;
        position: absolute;
        width: 70.62px;
        height: 70.5px;
        left: 200px;
        top: 360px;
      }

      .green-planet-2 {
        z-index: 0;
        position: absolute;
        width: 92.19px;
        height: 92.44px;
        right: -40px;
        top: 390px;
      }
    }

    .portal {
      position: absolute;
      width: 140%;
      top: 220px;
      left: -260px;

      &.token-sale-portal {
        // top: 520px;
        top: 370px;
      }
    }

    .portal-container {
      z-index: -1;
      width: 100%;
    }

    .spaceship {
      position: absolute;
      width: 350px;
      height: auto;
      left: -25px;
      top: 450px;
      overflow: hidden;

      &.token-sale-spaceship {
        // top: 750px;
        left: -40px;
        top: 600px;
      }
    }

    .guild-heading {
      margin-left: 0;
      width: 80%;

      &.token-sale-heading {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
      }

      &.token-sale-heading-small {
        flex-direction: column;
      }

      .video-placeholder {
        position: relative;
        align-self: center;
        width: 90%;
        border-radius: 8px;
        overflow: hidden;
        filter: drop-shadow(0px 0px 100px #4f4f4f);

        img {
          object-fit: cover;

          height: 100%;
          width: 100%;
          // height: min-content;
          filter: drop-shadow(0px 0px 100px #4f4f4f);
          align-self: center;
          border-radius: 8px;
        }

        a .play-button {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 52px;
          height: 52px;
          aspect-ratio: 1;

          border-radius: 7px;
        }
      }

      .guild-heading-column {
        display: flex;
        align-self: center;
        margin-left: 0;
        width: 90%;
        justify-content: space-between;
        margin-top: 40px;
      }

      .guild-header {
        margin-top: 16;
      }

      .guild-body {
        width: 100%;
        padding-left: 0;

        &.spacing {
          margin-top: 28px;
        }

        &.token-sale {
          margin-top: 14px;
        }

        .element {
          &.spacing {
            margin-bottom: 20px;
          }
        }
      }

      .guild-stats {
        justify-content: space-between;
        flex-direction: column;
        align-self: flex-end;

        &.spacing {
          margin-top: 0;
        }

        &.token-sale {
          margin-top: 60px;
          margin-bottom: 0 !important;
        }

        .guild-stats-element {
          &.spacing {
            margin-top: 0;
          }

          &.token-sale {
            margin-bottom: 30px;
          }

          .numbers {
            &.token-sale-numbers {
              font-size: 40px;
            }
          }
        }
      }
      .buttons-row {
        .header-row {
          margin-top: 0;
        }

        a .header-white-paper {
          text-decoration: underline;
          font-size: 14px;
          align-self: center;

          &.spacing {
            margin-left: 20px;
          }
        }
      }
    }

    .headline {
      &.spacing {
        margin-bottom: 20px;
      }
    }

    .guild-header-title {
      line-height: 100%;
      margin-bottom: 0;
      margin-top: 16px;

      &.token-sale {
        font-size: 30px;
      }
    }

    .happening-now {
      font-size: 22px;
    }

    .countdown {
      font-size: 22px;
      margin-top: 12px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .guild-section {
    margin-bottom: 20px;
    margin-top: 45px;
    z-index: 10;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;

    &.token-sale-section {
      margin-top: 0;
      padding-top: 5px;
      margin-right: 0px;
      margin-bottom: 50px;
    }

    .guild-objects {
      position: relative;

      &.token-sale-objects {
        // top: 300px;
        top: 200px;
        left: 20px;
      }

      .green-planet-1 {
        z-index: 0;
        position: absolute;
        width: 19vw;
        height: auto;
        left: 25vw;
        top: 42vh;
      }

      .green-planet-2 {
        z-index: 0;
        position: absolute;
        width: 25vw;
        height: auto;
        right: -4vw;
        top: 45vh;
      }
    }

    .portal-container {
      z-index: -1;
      width: 100%;
      position: relative;
      padding: 0;

      .portal {
        position: absolute;
        width: 140%;
        top: 31vh;
        left: -60vw;

        &.token-sale-portal {
          // top: 480px;
          top: 420px;
        }
      }
    }

    .spaceship {
      position: absolute;
      width: 290px;
      height: auto;
      left: -45px;
      top: 60vh;
      overflow: hidden;

      &.token-sale-spaceship {
        // top: 800px;
        top: 620px;
      }
    }

    .guild-heading {
      margin-top: 0;
      margin-left: 0;
      width: 90%;

      .guild-header {
        margin-top: 16;
      }

      .guild-body {
        padding-left: 0;

        &.spacing {
          margin-top: 28px;
        }

        .element {
          &.spacing {
            margin-bottom: 20px;
          }
        }
      }

      .guild-stats {
        justify-content: space-between;
        flex-direction: column;
        align-self: flex-end;

        &.spacing {
          margin-bottom: 45px;
        }
      }

      &.spacing {
        margin-bottom: 24.17px;
      }
    }

    .headline {
      &.spacing {
        margin-bottom: 20px;
      }
    }

    .guild-header-title {
      line-height: 100%;
      margin-bottom: 0;
      margin-top: 16px;
    }
  }
}

@media only screen and (max-width: 320px) {
  .guild-section {
    &.token-sale-section {
      margin-bottom: 70px;
    }

    .guild-heading {
      .buttons-row {
        .header-row {
          margin-top: 0;
        }

        a .header-white-paper {
          text-decoration: underline;
          font-size: 12px;
          align-self: center;

          &.spacing {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
