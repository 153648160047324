.video-section {
    display: flex;
    justify-content: center;
    color: white;
    position: relative;
    z-index: 9999;
    margin-top: 100px;

    .video-list {
      display: flex;
      justify-content: center;
      gap: 2vw;
      background-image: url('../../images/video-border.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;  
      padding-top:10vw;
      padding-bottom:10vw;
      padding-left:5vw;
      padding-right:5vw;
      
        .video-list-placeholder, .video-list-placeholder-2 {
          position: relative;
          width: 35vw;

            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
              align-self: center;
//              border-radius: 8px;
            }
      
            .play-list-button {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              width: 43.3px;
              height: 43.3px;
              aspect-ratio: 1;
              border-radius: 7px;
            }
          
          }            
      }

  }

  @media only screen and (min-width: 1024px) {
    .video-section {
      .video-list {
        .video-list-placeholder, .video-list-placeholder-2 {
          width: 20vw;
          .play-list-button {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 80.3px;
            height: 80.3px;
            aspect-ratio: 1;
            border-radius: 7px;
          }          
          } 
        }
      }
  }

  @media only screen and (max-width: 1024px) {
    .video-section {
      margin: 30px auto;
      
      .video-list {
        background-image: url('../../images/video-mobile-bg.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        padding: 11vw;

          .video-list-placeholder {
          background-size: contain;
          background-repeat: no-repeat;
          // background-position: center;
          position: relative;
          width: 70vw;
          height: 60vw;

            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
              align-self: center;
//              border-radius: 8px;
            }
      
            .play-list-button {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              width: 43.3px;
              height: 43.3px;
              aspect-ratio: 1;
              border-radius: 7px;
            }
          
        }
        .video-list-placeholder-2{
          display: none;
        }        
      }
    }
  }
