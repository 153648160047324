// APP COLOURS
$avo-grey-1: #f2f2f2;
$avo-grey-2: #e0e0e0;
$avo-grey-3: #bdbdbd;
$avo-grey-4: #b2b2b2;
$avo-grey-5: #4f4f4f;
$avo-grey-6: #2a2a2b;
$avo-grey-7: #363636;
$avo-grey-footer: #828282;
$avo-light-grey: #E6E6E6;
$avo-light-grey-2: #F5F5F5;
$avo-black-1: #202020;
$avo-black-2: #181819;
$avo-black-3: #202022;
$avo-pure-black: #060606;
$avo-white: #ffffff;
$avo-green-1: #aebf45;
$avo-green-2: #536a33;
$avo-green-3: #484825;
$avo-purple-1: #8f01ff;
$avo-purple-2: #5001b6;
$avo-purple-3: #554f59;
$avo-purple-4: #2a232f;
$avo-purple-5: #180e2b;

// FONTS
$poppins-regular: 400;
$poppins-medium: 500;
$poppins-semi-bold: 600;
$poppins-bold: 700;
$poppins-black: 900;

$gilroy-black: 800;

// TYPOGRAPHY
$font-family-sans-serif: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji";
$font-family-base: "Poppins",
$font-family-sans-serif;
$font-family-footer: "Open Sans",
$font-family-sans-serif;
$font-family-furore: "Furore";
$font-family-gilroy: "Gilroy-Bold";
$font-family-gilroy-regular: "Gilroy-Regular";

$font-size-base: 1rem;

// LAYOUT
$sidebar-width: 230px;

$app-header-offset-tablet: 110px;
$app-header-offset-mobile: 65px;

$new-app-header-offset: 87.58px;