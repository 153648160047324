.full-sidebar {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(22, 22, 22, 0.8);
  right: -100%;
  top: $new-app-header-offset;
  z-index: 110;
  transition: all 0.5s ease;

  .nav-list {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: 20px 5px;
      margin: 0 30px;
      border-bottom: 1px solid $avo-grey-5;
    }

    .full-sidebar-item {
      .link-icon {
        margin-left: 10px;
      }
    }
  }

  .btn-connect {
    display: unset;
    width: 220px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .expandable {
    .accordion-icon {
      margin-left: 8px;
      height: 20px;
    }
    .expanded-contents {
      height: 0;
      overflow: hidden;
      transition: height ease 0.5s;
      ul {
        list-style: none;
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
          padding: 20px 5px;
          margin: 0 20px;
          border-bottom: unset;

          &:first-child {
            margin-top: 7px;
          }

          p {
            color: $avo-grey-3;
          }
        }
      }
      .link-icon {
        margin-right: 15px;
      }
    }
  }
}

.full-sidebar-active {
  right: 0;
  overflow-y: scroll;
}

@media only screen and (max-width: 480px) {
  .full-sidebar {
    top: 58.8px;
  }
}
