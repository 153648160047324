.auction-buttons-container {
  margin: 20px;

  .btn-cta {
    cursor: pointer;
    //position: absolute;
    bottom: -10px;
    left: 130px;
    background: none;
    border: none;
    background-image: url("../../images/btn-auction-bg.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 193.52px;
    height: 41px;
    border-radius: 22px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    font-family: $font-family-furore;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 1.74194px;
    text-transform: uppercase;
    color: #261655;

    max-width: 350px;
    max-height: 72px;

    @media only screen and (min-width: 769px) {
      //bottom: -1vw;
      left: 9.03vw;
      width: 13.4028vw;
      height: 2.848vw;
      border-radius: 1.53vw;
      // font-size: 0.8vw;
      font-size: 0.85vw;
      font-weight: 400;
      line-height: 1.111111vw;
    }
  }
}

@media only screen and (max-width: 768px) {
  .auction-buttons-container {
    left: 150px;

    .btn-cta {
      cursor: pointer;
      //position: absolute;
      bottom: -10px;
      left: 130px;
      width: 193.52px;
      height: 41px;
      border-radius: 22px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 1.74194px;
      text-transform: uppercase;
      color: #261655;
    }
  }
}

@media only screen and (min-width: 2400px) {
  .auction-buttons-container {
    .btn-cta {
      width: 13.4028vw;
      height: 2.848vw;
      font-size: 22px;
    }
  }
}

// @media only screen and (min-width: 3700px) {
//   .auction-buttons-container {
//     .btn-cta {
//       height: 42px;
//     }
//   }
// }

@media only screen and (max-width: 480px) {
  .auction-buttons-container {
    // margin-top: 380px;

    .btn-cta {
      //width: 150px;
      // height: 50px;
      font-size: 12px;
      // box-shadow: 0px 4px 15px rgba(216, 255, 132, 0.6);
    }
  }
}

@media only screen and (max-width: 320px) {
  .auction-buttons-container {
    // margin-top: 250px;
  }
}
