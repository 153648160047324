.dashboard-page {
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  // overflow-y: scroll;

  .dashboard-title {
    position: absolute;
    top: 30px;
    left: 40px;
    font-size: 26px;
  }
}

@media only screen and (max-width: 1024px) {
  .dashboard-page {
    .dashboard-title {
      position: unset;
      text-align: center;
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .dashboard-page {
    padding-left: 20px;
    padding-right: 20px;

    .prices-content {
      flex-direction: column;
    }
  }
}
