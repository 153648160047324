.footer-section {
  align-self: center;
  z-index: 10;
  background-color: $avo-pure-black;
  padding: 0 190px 170px 190px;
  width: 100%;

  .footer-components {
    width: 100%;

    .footer-links-section {
      width: 450px;

      th {
        text-align: start;
      }

      .community-link-footer {
        &::after {
          margin-left: 7px;
          content: url("../../images/icons/external-link-dark.svg");
        }
      }
    }

    .footer-logo {
      margin: 0;
    }

    .social-media-section {
      margin-top: 10px;
      max-width: 320px;

      .social-media-title {
        text-align: center;
      }

      .social-media-icons {
        width: 260px;
        margin-top: 30px;
      }
    }
  }

  .copyright-section {
    flex-direction: row;
    margin-top: 95px;

    .liability {
      width: 63%;
    }

    .copyright {
      width: 37%;
    }
  }
}

@media only screen and (max-width: 1390px) {
  .footer-section {
    .footer-components {
      .footer-logo {
        margin-right: 10px;
      }
    }
  }
}

@media only screen and (max-width: 870px) {
  .footer-section {
    padding: 0 60px 18px 60px;

    .copyright-section {
      margin-top: 30px;
      flex-direction: column;

      .liability {
        width: 100%;
      }

      .copyright {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 65px;
      }
    }

    .footer-components {
      flex-direction: column;
      align-items: center;
      .footer-links-section {
        width: 100%;
      }

      .social-media-section {
        margin-top: 0;
        .social-media-icons {
          margin-top: 15px;
        }
      }

      .footer-logo {
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .footer-section {
    padding: 0 20px 0 20px;

    .copyright-section {
      .liability {
        text-align: center;
      }

      .copyright {
        justify-content: center;
      }
    }

    .footer-components {
      .social-media-section {
        max-width: 200px;
      }
    }
  }
}
