
.main-bridge {
    background: url("../../../assets/images/backgrounds/av-bridge-bg-web.svg") no-repeat;
    background-size: cover;
    background-position: top;
    min-height: 100%;
    height: 110vh;
    position: relative;
}

.main-bridge .content {
    text-align: center;
    padding-top: 5%
}

@media only screen and (min-width:360px) {
    @media only screen and (max-width:700px) {
        .main-bridge {
            background: url("../../../assets/images/backgrounds/av-bridge-bg-mobile.svg") no-repeat;
            background-size: cover;
            background-position: top;
            min-height: 100%;
            height: 110vh;
            position: relative;
        }
    }

    @media only screen and (max-width:1024px) {
        .main-bridge .content {
            text-align: center;
            padding-top: 10%
        }
    }
}
