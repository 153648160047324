.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: $sidebar-width;
  height: 100%;
  padding: 20px;

  background-color: $avo-black-2;

  .sidebar-logo {
    width: 180px;
    margin-top: 15px;
    margin-bottom: 35px;
  }

  .nav-list {
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;

    .navbar-item {
      text-decoration: none;
      width: 220px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 10px;
      border-radius: 10px;
      opacity: 0.4;

      .navbar-item-icon {
        margin-right: 15px;
        margin-left: 25px;
      }

      &:hover {
        opacity: 1;
      }
    }

    .selected-navbar-item {
      text-decoration: none;
      width: 220px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 10px;
      border-radius: 10px;
      background-color: $avo-grey-6;

      .navbar-item-icon {
        margin-right: 15px;
        margin-left: 25px;
      }

      .selected-navbar-item-text {
        color: $avo-green-1;
      }
    }
  }

  .sidebar-bottom-section {
    margin-top: 60vh;
    width: 100%;
    align-items: center;

    .white-paper-btn {
      all: unset;
      cursor: pointer;
      margin-bottom: 30px;
    }
    .back-to-home-btn {
      all: unset;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .back-to-home-icon {
        margin-right: 15px;
      }
      .back-to-home-text {
        
      }
    }
    
  }
}

@media only screen and (max-width: 1024px) {
  .sidebar {
    left: unset;
    right: -270px;
    top: $app-header-offset-tablet;
    z-index: 35;
    transition: all 0.5s ease;

    .sidebar-bottom-section {
      margin-top: 50vh;
      align-items: unset;
      align-self: flex-start;
    }

    .nav-list {
      .navbar-item {
        .navbar-item-icon {
          margin-left: 0;
        }
      }
      .selected-navbar-item {
        background-color: transparent;
        .navbar-item-icon {
          margin-left: 0;
        }
      }
    }
  }
  .sidebar-active {
    right: 0;
  }
}

@media only screen and (max-width: 480px) {
  .sidebar {
    top: $app-header-offset-mobile;

    .sidebar-bottom-section {
      margin-top: 38vh;
    }
  }
}
