.page-tc {
  padding-top: 82px;

  &__container {
    padding: 46px 60px 30px;
    background-color: white;
  }
}

@media only screen and (max-width: 480px) {
  .page-tc {
    padding-top: 0;
  }
}

.ol-heading {
  font-size: 24px;
}

.ol-paragraph {
  font-size: 16px;
}